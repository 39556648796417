<template>
  <v-sheet rounded="lg" class="background">
    <aircraft-modal v-model="dialog" v-on:add="updateValue($event)" />
    <v-container>
      <v-row>
        <v-col cols="10">
          <v-autocomplete
              label="Enter Registration (include hyphens)"
              v-if="showSelector"
              v-bind:value="value"
              :disabled="disabled"
              :rules="rules"
              outlined
              dense
              :items="items"
              item-text="registration"
              item-value="registration"
              :search-input.sync="search"
              hide-no-data
              clearable
              no-filter
              @input="updateValue($event)">

            <template v-slot:selection="data">
              {{ data.item.registration }}{{ !data.item.tailNumber ? '':` (${data.item.tailNumber})`}}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>{{ data.item.registration }}{{ !data.item.tailNumber ? '':` (${data.item.tailNumber})`}}</v-list-item-content>
            </template>
<!--            <template v-slot:no-data>-->
<!--              <v-alert type="info" v-if="items.length=0">-->
<!--                <div class="font-weight-bold">Aircraft not found</div>-->
<!--                <div class="text-body-2">Click the button below to add the aircraft to the database.</div>-->
<!--              </v-alert>-->
<!--              <div v-else></div>-->
<!--            </template>-->
<!--            <template v-slot:append-item>-->
<!--              <div class="text-subtitle-1 ml-3">Recent Aircraft</div>-->
<!--              <v-divider class="my-2"/>-->
<!--              <v-btn text block @click="dialog=true">-->
<!--                <div class="text-body-2 ml-3"><v-icon>mdi-plus-circle</v-icon>Add a new Aircraft</div>-->
<!--              </v-btn>-->
<!--            </template>-->
          </v-autocomplete>
          <div class="text-body-2" v-else>{{ value }}</div>
        </v-col>
<!--        <v-col cols="1" v-if="this.default">-->
<!--          <div class="">-->
<!--            <app-btn class="px-2" fab small @click="populateWithDefault" v-if="showFavourite">-->
<!--              <v-icon class="mx-1">mdi-{{ showSelector ? 'star' : 'pencil' }}</v-icon>-->
<!--            </app-btn>-->
<!--          </div>-->
<!--        </v-col>-->
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
// import AppAutocomplete from "@/components/app/Autocomplete";
import {AircraftHttp} from "@/http/AircraftHttp";
import AircraftModal from "@/components/modals/AircraftModal";

export default {
  name: 'AircraftPicker',
  components: {AircraftModal},
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showFavourite: {
      type: Boolean,
      default: true
    },
    default: {
      type: String
    }
  },
  data: () => {
    return {
      isLoading: false,
      items: [],
      search: null,
      showSelector: true,
      defaultAircraft:{
        name:null,
        _id:null
      },
      rules: [
        v => !!v || 'selection is required',
      ],
      dialog:false
    }
  },
  computed: {
    // ...mapState("aircraft", ["aircraft"]),
  },

  watch: {
    async search (val) {
      // Value is blank
      if (!val) return;
      // Value is less than blank
      if (val && val.length < 2) return;


      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      try {
        this.items = await AircraftHttp.searchForAircraftByQuery(val).then(result => result.data);
      } catch (e) {
        alert(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    // await this.setAircraft();
    // this.selectedAircraft = this.value
    if(this.default) {
      this.updateValue(this.default)
    }
  },
  methods: {
    ...mapActions("aircraft", ["setAircraft"]),
    populateWithDefault() {
      this.updateValue(this.default)
      this.showSelector = !this.showSelector
    },
    async updateValue(value) {
      this.search = value
      this.$emit("input", value)
      this.items = await AircraftHttp.searchForAircraftByQuery(value).then(result => result.data);
      const aircraft = this.items.find(item => item.registration === value)
      this.$emit('aircraft', aircraft)
    },
  }
};
</script>