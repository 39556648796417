import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"background",attrs:{"rounded":"lg"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[(_vm.showSelector)?_c(VAutocomplete,{attrs:{"label":_vm.label,"value":_vm.value,"disabled":_vm.disabled,"rules":_vm.rules,"outlined":"","dense":"","items":_vm.airports,"item-value":"name","item-text":"name","search-input":_vm.search,"hide-no-data":"","clearable":"","no-filter":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.updateValue($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(data.item.ident)+" "+_vm._s(data.item.name)+" ("+_vm._s(data.item.iso_country)+" ")])]}},{key:"item",fn:function(data){return [_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(data.item.ident)+" "+_vm._s(data.item.name)+" ("+_vm._s(data.item.iso_country)+") ")])]}}],null,false,19981717)}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }