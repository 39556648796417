import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"600px"}},[_c(VCard,[_c(VCardTitle,[_vm._v("New Aircraft")]),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("Registration")])]),_c(VCol,{attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.registrationAlreadyInDB)+" "),_c('app-text-field',{attrs:{"label":"Registration","rules":_vm.rules},on:{"blur":_vm.validate},model:{value:(_vm.registration),callback:function ($$v) {_vm.registration=$$v},expression:"registration"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("Type")])]),_c(VCol,{attrs:{"cols":"9"}},[_c('aircraft-type-picker',{on:{"typeSelected":function($event){return _vm.updateTypeDetails($event)}},model:{value:(_vm.aircraftType),callback:function ($$v) {_vm.aircraftType=$$v},expression:"aircraftType"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("Similar Registrations")])]),_c(VCol,{attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.similarRegistrations.length > 0 ? _vm.similarRegistrations:'none')+" ")])],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('app-btn',{attrs:{"color":"secondary darken-1"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('app-btn',{attrs:{"color":"primary darken-1","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }