<template>
  <v-text-field
      class="text-body-2 px-2"
      v-bind="$attrs"
      v-on="$listeners"
      dense
      outlined/>
</template>

<script>
export default {
  name: "AppTextField"
}
</script>

<style scoped>

</style>