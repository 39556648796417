export class AircraftTypeHttp {

    /**
     * Search bookings
     * @param q
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async search(q) {
        try {
            return await window.axios.get(`aircraft-type/search?q=${q}`);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}