<template>
  <v-autocomplete
      label="Enter Aircraft Type"
      v-if="showSelector"
      v-bind:value="value"
      :disabled="disabled"
      :rules="rules"
      outlined
      dense
      :items="items"
      item-value="_id"
      item-text="modelFullName"
      :search-input.sync="search"
      clearable
      no-filter
      @input="updateValue($event)">
    <template v-slot:no-data>
        <div class="text-body-2" v-if="search">No entries match your search</div>
    </template>
    <template v-slot:selection="data">
      <span>{{ data.item.designator }} </span>- {{ data.item.modelFullName }}
    </template>
    <template v-slot:item="data">
      <span>{{ data.item.designator }} </span>- {{ data.item.modelFullName }}
    </template>
  </v-autocomplete>
</template>

<script>
import {AircraftTypeHttp} from "@/http/AircraftTypeHttp";

export default {
  name: 'AircraftTypePicker',

  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showFavourite: {
      type: Boolean,
      default: true
    },
    default: {
      type: String
    }
  },
  data: () => {
    return {
      isLoading: false,
      items: [],
      search: null,
      showSelector: true,
      defaultAircraft: {
        name: null,
        _id: null
      },
      rules: [
        v => !!v || 'selection is required',
      ],
      dialog: false
    }
  },
  computed: {
    // ...mapState("aircraft", ["aircraft"]),
  },

  watch: {
    async search(val) {
      // Value is blank
      if (!val) return;
      // Value is less than blank
      if (val && val.length < 3) return;

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      try {
        this.items = await AircraftTypeHttp.search(val).then(result => result.data);
      } catch (e) {
        alert(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    // await this.setAircraft();
    // this.selectedAircraft = this.value
  },
  methods: {
    async updateValue(value) {
      this.search = value
      this.$emit("input", value)
      const typeDetails = this.items.find(item => item._id === value)
      this.$emit('typeSelected',typeDetails)
    },
  }
};
</script>