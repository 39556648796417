<template>
  <v-container v-if="activePerson">
    <v-row class="justify-center">
      <v-col cols="12" md="5" lg="4">
        <div class="text-h6 font-weight-bold">Settings and Preferences</div>
        <v-list>
          <v-item-group v-model="active" color="primary">
            <v-list-item v-for="(item,i) in items" :key="i" :disabled="item.routeName === $route.name">
              <v-list-item-title @click="$router.push({name:`${item.routeName}`})">{{item.text}}</v-list-item-title>
            </v-list-item>
          </v-item-group>
        </v-list>
      </v-col>
      <v-col cols="1" v-if="$vuetify.breakpoint.mdAndUp">
        <v-divider vertical/>
      </v-col>
      <v-col cols="12" md="6" lg="7">
        <v-sheet v-if="activePerson">
          <div class="text-h6">Flight Defaults</div>
          <v-list-item v-if="!editDefaultAircraft">
            <v-list-item-icon>
              <v-icon>mdi-airplane</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ activePerson.flyingData.defaultAircraft }}</v-list-item-title>
              <v-list-item-subtitle>Default Aircraft</v-list-item-subtitle>
              <div>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-row align="center"
                     justify="center" v-if="isEditable">
                <v-col>
                  <!--            Reserved for Future visibility of per user item information sharing-->
                </v-col>
                <v-col>
                  <app-btn fab @click="editDefaultAircraft = !editDefaultAircraft">
                    <v-icon>{{ editDefaultAircraft ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
                  </app-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <div v-if="editDefaultAircraft">
            <aircraft-picker v-model="activePerson.flyingData.defaultAircraft"
                             :default="activePerson.flyingData.defaultAircraft"
                             :disabled="!editDefaultAircraft"/>
            <v-divider/>
            <v-row class="justify-end">
              <v-col>
                <v-btn small @click="editDefaultAircraft = false">Cancel</v-btn>
                <v-btn small @click="save" :disabled="!activePerson.flyingData.defaultAircraft">Save</v-btn>
              </v-col>
            </v-row>
          </div>
          <v-list-item v-if="!editDefaultAirport">
            <v-list-item-icon>
              <v-icon>mdi-airport</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ activePerson.flyingData.homeAirfield }}</v-list-item-title>
              <v-list-item-subtitle>Default Airport</v-list-item-subtitle>
              <div>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-row align="center"
                     justify="center" v-if="isEditable">
                <v-col>
                  <!--            Reserved for Future visibility of per user item information sharing-->
                </v-col>
                <v-col>
                  <app-btn fab @click="editDefaultAirport = !editDefaultAirport">
                    <v-icon>{{ editDefaultAirport ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
                  </app-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <div v-if="editDefaultAirport">
            <airport-picker label="Enter Default Airport" v-model="activePerson.flyingData.homeAirfield"
                            :default="activePerson.flyingData.homeAirfield"
                            :disabled="!editDefaultAirport"/>
            <v-divider/>
            <v-row class="justify-end">
              <v-col>
                <v-btn small @click="editDefaultAirport = false">Cancel</v-btn>
                <v-btn small @click="save" :disabled="!activePerson.flyingData.homeAirfield">Save</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mapState} from "vuex";
import {isAuthorised} from "@/util/helpers";
import AircraftPicker from "@/components/shared/AircraftPicker";
import {PersonHttp} from "@/http/PersonHttp";
import AirportPicker from "@/components/shared/AirportPicker";
import AppBtn from "@/components/app/Btn";

export default {
  name: "SettingsPage",
  components: {AppBtn, AirportPicker, AircraftPicker},
  computed: {
    ...mapState("person",["activePerson","loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },
  data() {
    return {
      active:0,
      editDefaultAircraft:false,
      editDefaultAirport:false,
      items: [
        {text:'Settings and Preferences',routeName:'Settings and Preferences'},
      ]
    }
  },
  methods: {
    save() {
      this.editDefaultAircraft = false
      this.editDefaultAirport = false
      const data = this.activePerson
      const id = this.activePerson._id
      delete data._id
      PersonHttp.updatePersonDetails(id, data)
      this.activePerson._id = id
    },
  }
}
</script>

<style scoped>

</style>