import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"background",attrs:{"rounded":"lg"}},[_c('aircraft-modal',{on:{"add":function($event){return _vm.updateValue($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[(_vm.showSelector)?_c(VAutocomplete,{attrs:{"label":"Enter Registration (include hyphens)","value":_vm.value,"disabled":_vm.disabled,"rules":_vm.rules,"outlined":"","dense":"","items":_vm.items,"item-text":"registration","item-value":"registration","search-input":_vm.search,"hide-no-data":"","clearable":"","no-filter":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.updateValue($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.registration)+_vm._s(!data.item.tailNumber ? '':` (${data.item.tailNumber})`)+" ")]}},{key:"item",fn:function(data){return [_c(VListItemContent,[_vm._v(_vm._s(data.item.registration)+_vm._s(!data.item.tailNumber ? '':` (${data.item.tailNumber})`))])]}}],null,false,1590660046)}):_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.value))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }