<template>
  <v-dialog v-bind:value="value" max-width="600px">
    <v-card>
      <v-card-title>New Aircraft</v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="3">
                <div class="text-subtitle-1">Registration</div>
              </v-col>
              <v-col cols="9">
                {{registrationAlreadyInDB}}
                <app-text-field v-model="registration" label="Registration" :rules="rules"
                                @blur="validate"></app-text-field>
              </v-col>
              <v-col cols="3">
                <div class="text-subtitle-1">Type</div>
              </v-col>
              <v-col cols="9">
                <aircraft-type-picker v-model="aircraftType" v-on:typeSelected="updateTypeDetails($event)"/>
              </v-col>

            <v-col cols="3">
              <div class="text-subtitle-1">Similar Registrations</div>
            </v-col>
            <v-col cols="9">
              {{ similarRegistrations.length > 0 ? similarRegistrations:'none' }}
            </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <app-btn
            color="secondary darken-1"
            @click="close"
        >
          Cancel
        </app-btn>
        <app-btn
            color="primary darken-1"
            @click="save"
            :disabled="!valid"
        >
          Save
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AircraftTypePicker from "@/components/shared/AircraftTypePicker";
import AppTextField from "@/components/app/TextField";
import AppBtn from "@/components/app/Btn";
import {AircraftHttp} from "@/http/AircraftHttp";

export default {
  name: "AircraftModal",
  components: {AppBtn, AppTextField, AircraftTypePicker},
  props: {
    value: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      similarRegistration:[],
      search: null,
      valid: false,
      nationalRegistrationMark:null,
      registration: null,
      aircraftType: null,
      designator: null,
      modelFullName: null,
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  },
  computed: {
    registrationAlreadyInDB(){
      return this.similarRegistration.length > 0
    },
    similarRegistrations(){
      return this.similarRegistration.map(a => a.registration)
    },
    aircraft() {
      return {
        // nationalRegistrationMark: this.nationalRegistrationMark.toUpperCase(),
        registration: this.registration.toUpperCase(),
        aircraftType: this.aircraftType,
        bookingColor: '#000000',
        designator: this.designator,
        modelFullName: this.modelFullName
      }
    }
  },
  watch: {
    async registration (val) {
      // Value is blank
      if (!val) return;
      // Value is less than blank
      if (val && val.length < 2) return;


      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      try {
        this.similarRegistration = await AircraftHttp.searchForAircraftByQuery(val).then(result => result.data);
      } catch (e) {
        alert(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    validate() {
      this.valid = this.$refs.form.validate()
    },
    close() {
      this.valid = false
      this.nationalRegistrationMark = null
      this.registration = null
      this.aircraftType = null
      this.designator = null
      this.modelFullName = null
      this.$emit('input', false)
    },
    save() {
      this.$refs.form.validate()
      AircraftHttp.createNew(this.aircraft)
      this.$emit('add', this.registration)
      this.close()
    },
    updateTypeDetails(typeDetails) {
      this.designator = typeDetails.designator
      this.modelFullName = typeDetails.modelFullName
    }
  }
}
</script>

<style scoped>

</style>