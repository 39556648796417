<template>
<v-autocomplete
  v-bind="$attrs"
  v-on="$listeners"
  dense
  class="text-body-2"
  clearable
  outlined>

</v-autocomplete>
</template>

<script>
export default {
name: "AppAutocomplete"
}
</script>

<style scoped>

</style>