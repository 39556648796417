<template>
  <v-sheet rounded="lg" class="background">
    <v-container>
      <v-row>
        <v-col cols="10">
          <v-autocomplete
              :label="label"
              v-if="showSelector"
              v-bind:value="value"
              :disabled="disabled"
              :rules="rules"
              outlined
              dense
              :items="airports"
              item-value="name"
              item-text="name"
              :search-input.sync="search"
              hide-no-data
              clearable
              no-filter
              @input="updateValue($event)">
            <template v-slot:selection="data">
              <div class="text-body-2">
                {{ data.item.ident }} {{ data.item.name }} ({{ data.item.iso_country}}
              </div>
            </template>
            <template v-slot:item="data">
              <div class="text-body-2">
                {{ data.item.ident }} {{ data.item.name }} ({{data.item.iso_country}})
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

import {AirportHttp} from "../../http/AirportHttp";
import AppAutocomplete from "@/components/app/Autocomplete";

export default {
  name: 'airport-picker',
  components: {AppAutocomplete},
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    default: {
      type: String
    },
    showFavourite: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
    }

  },
  calculated: {},
  data() {
    return {
      defaultAirport: {
        name: null,
        ident: null,
        _id: null
      },
      airport: {},
      airports: [],
      isLoading: false,
      search: '',
      selectedItem: "",
      model: null,
      descriptionLimit: 60,
      showSelector: true,
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  },
  async mounted() {
    if (!this.value && this.default) {
      this.airports = await AirportHttp.searchForAirportByQuery(this.default).then(result => result.data);
      await this.updateValue(this.default)
    }
    if (this.value) {
      this.isLoading = true;
      try {
        this.airports = await AirportHttp.searchForAirportByQuery(this.value).then(result => result.data);
      } catch (e) {
        alert(e);
      } finally {
        this.isLoading = false;
      }
    }

  },
  // async mounted() {
  //   // await this.setAircraft();
  //   // this.selectedAircraft = this.value
  //   if(this.default) {
  //     await this.updateValue(this.default)
  //   }
  // },
  watch: {
    async search(val) {
      if (!val) return;
      if (val && val.length < 4) return;
      if (val) {
        this.isLoading = true;
        try {
          this.airports = await AirportHttp.searchForAirportByQuery(val).then(result => result.data);
        } catch (e) {
          alert(e);
        } finally {
          this.isLoading = false;
        }
      }
    }
  },

  methods: {
    populateWithDefault() {
      this.updateValue(this.default)
      this.showSelector = !this.showSelector
    },
    async updateValue(value) {
      if (!value) return;
      this.$emit("input", value);
      if (!value || value == null) {
        this.$emit('updateAirport', this.defaultAirport)
      }
      this.$emit('commitChanges')
      this.airport = this.airports.find(a => a.name === value)
      this.$emit('updateAirport', this.airport)
    }
  },

}
</script>
